
import { AuthService } from './../services/auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route } from '@angular/router';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { User } from '../models/user.model';

@Injectable()
export class AuthGuard implements CanActivate {

  userSession : User;
  constructor(private authService: AuthService, private _router: Router) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    //if page reloaded, session storage will have current object, then behavioursubject will be updated 
    //with current object
    this.authService.updatedwaitingSession(true);
   
    if(sessionStorage.getItem("user")){
      let userFromStore = JSON.parse(sessionStorage.getItem("user"));
      this.authService.updatedDataSelection(userFromStore);
      sessionStorage.removeItem("user");
    }

    //getting current user
    this.authService.user.subscribe(data => {
      this.userSession = data;
    });
    
    if(!this.authService.isLoggedIn()){
      this._router.navigate(['/']);
      return false;
    }

    if(!this.userSession.token){
      this._router.navigate(['/']);
      return false;
    }

    return this.authService.getLoggedUser().pipe(
      map(data => {
        if(data === false){
          this._router.navigate(['/']);
          return false;
        }else{
          this.authService.updatedwaitingSession(false);
           return true;
        }
      })
    );
  }

}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  // baseurl: "https://unicoan.com/snp-demo/",
  baseurl: "http://localhost:4200/",

  // imageServer : 'https://unicoan.com/snap-backend/public/storage/',
  // imageServer: "https://unicoan.com/staging-snp-backend/public/storage/",
  imageServer: "http://localhost:8000/storage/",

  // apiserver : 'https://unicoan.com/snap-backend/public/api',
  // apiserver: "https://unicoan.com/staging-snp-backend/public/api",
  apiserver: "http://localhost:8000/api",
};
/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
